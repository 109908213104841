/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'preact/debug';

import { Provider } from 'jotai';
import { setup } from 'juhuui';
import React from 'react';
import ReactDOM from 'react-dom';

import theme from './src/theme';
import { store } from './src/zustand';
import './src/styles/global.css'

const { setState } = store;

setup(React.createElement, {
  theme,
  defaultFun: true,
  forwardRef: React.forwardRef,
});

export const onRouteUpdate = () => {
  setState({ modalLevel1Visible: false, modalLevel2Visible: false });
};

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>;
};

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.unstable_createRoot(container, {
      hydrate: true,
      hydrationOptions: { onHydrated: callback },
    }).render(element);
  };
};
