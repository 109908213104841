import create from 'zustand';
import createVanillaStore from 'zustand/vanilla';

interface Data {
  name: string;
  id: number;
}

type Navigation = number;

interface InView {
  0?: boolean;
  1?: boolean;
  2?: boolean;
  3?: boolean;
}

type State = {
  modalLevel1Visible: boolean;
  setModalLevel1Visible: (bol?: boolean) => void;
  modalLevel2Visible: boolean;
  setModalLevel2Visible: (bol?: boolean) => void;
  modalLevel1Data: Data;
  setModalLevel1Data: (data: Data) => void;
  modalLevel2Data: Data;
  setModalLevel2Data: (data: Data) => void;
  // resetModal: () => void;
  subNavigationServices: Navigation;
  setSubNavigationServices: (index: Navigation) => void;
  subNavigationPraxis: Navigation;
  setSubNavigationPraxis: (index: Navigation) => void;
  servicesInView: InView;
  setServicesInView: (items: InView) => void;
  praxisInView: InView;
  setPraxisInView: (items: InView) => void;
};

export const store = createVanillaStore<State>((set) => ({
  modalLevel1Visible: false,
  setModalLevel1Visible: (bol) =>
    set((state) => ({ modalLevel1Visible: bol || !state.modalLevel1Visible })),
  modalLevel2Visible: false,
  setModalLevel2Visible: (bol) =>
    set((state) => ({ modalLevel2Visible: bol || !state.modalLevel2Visible })),
  modalLevel1Data: { name: 'methods', id: 1 },
  setModalLevel1Data: (data) => set({ modalLevel1Data: data }),
  modalLevel2Data: { name: 'team', id: 1 },
  setModalLevel2Data: (data) => set({ modalLevel2Data: data }),
  // resetModal: () =>
  //   set({
  //     modalLevel1Data: { name: 'methods', id: 1 },
  //     modalLevel2Data: { name: 'team', id: 0 },
  //     modalLevel1Visible: false,
  //     modalLevel2Visible: false,
  //   }),
  subNavigationServices: 0,
  setSubNavigationServices: (index) => set({ subNavigationServices: index }),
  subNavigationPraxis: 0,
  setSubNavigationPraxis: (index) => set({ subNavigationPraxis: index }),
  servicesInView: { 0: false, 1: false, 3: false, 4: false },
  setServicesInView: (items) =>
    set((state) => ({ servicesInView: { ...state.servicesInView, ...items } })),
  praxisInView: { 0: false, 1: false, 3: false, 4: false },
  setPraxisInView: (items) =>
    set((state) => ({ praxisInView: { ...state.praxisInView, ...items } })),
}));

export const useStore = create(store);
