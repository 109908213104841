import { theme as themeIn } from 'juhuui';

const theme = {
  ...themeIn,
  // breakpoints: ['48em', '62em'],
  fontSizes: {
    ...themeIn.fontSizes,
    xs: '0.95rem', // 19px -> 19/20=0.95 (20 is a random number providing decnt values)
    sm: '1.05rem', // Smallest mobile viepwort size
    md: '1.2rem', // 24px -> 24/20=1.2
    lg: '1.6rem', // 32px -> 32/20=1.6
    xl: '2rem', // 40px -> 40/20=2
    '2xl': '2.2rem', // Biggest medium viewport size
    '3xl': '2.6rem', // 52px -> 52/20=2.6
    // '4xl': '2.25rem',
    // '5xl': '2.65rem',
    // '6xl': '3rem',
    // '7xl': '3.5rem',
    // '8xl': '4rem',
  },
  sizes: {
    ...themeIn.sizes,
    '0': '0px',
    // '1': '0.25rem',
    // '2': '0.5rem',
    // '3': '0.75rem',
    // '4': '1rem',
    // '5': '1.25rem',
    // '6': '1.5rem',
    // '8': '2rem',
    // '10': '2.5rem',
    // '12': '3rem',
    // '14': '3.5rem',
    // '16': '4rem',
    // '20': '5rem',
    // '24': '6rem',
    // '32': '8rem',
    // '40': '10rem',
    // '48': '12rem',
    // '56': '14rem',
    // '64': '16rem',
    // '3xs': '14rem',
    // '2xs': '16rem',
    // xs: '20rem',
    // sm: '24rem',
    // md: '28rem',
    // lg: '32rem',
    // xl: '36rem',
    // '2xl': '42rem',
    // '3xl': '48rem',
    // '4xl': '56rem',
    // '5xl': '64rem',
    // '6xl': '72rem',
    // full: '100%',
    // half: '50%'
  },
  fonts: {
    // light: 'banana_light, system-ui, sans-serif',
    // heading: 'banana_light, system-ui, sans-serif',
  },
  colors: {
    ...themeIn.colors,
    tom: {
      bg: '#EFEFEA',
      bgRGB: 'rgba(239, 239, 234, 0.9)',
      bgInverted: '#212420',
      bgInvertedRGB: 'rgba(33, 36, 32, 0.9)',
      bgBright: '#FFFFFF',
      bgBrown: '#877D62',
      bgBrownRGB: 'rgba(135, 125, 98, 0.9)',
      bgButton: '#3d4a36',
      text: '#000000',
      textInverted: '#f3f3ee',
      textHighlight: '#4d784e',
      textSecondary: '#939B8F',
      line: '#C3CEC4',
      lineBg: '#E0E0D9',
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  icons: {
    ...themeIn.icons,
    more:
      '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="none" stroke="#C3CEC4" strokeWidth="1"><circle cx="16" cy="16" r="15.5" fill="none"/></g><path d="M-18706-15852l6.27,5.541-6.27,5.26" transform="translate(18719.842 15863.044)" fill="none" stroke="#000000" strokeWidth="1.2"/></svg>',
    open: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <g id="Ellipse_97" data-name="Ellipse 97" fill="none" stroke="#c3cec4" strokeWidth="1">
          <circle cx="18" cy="18" r="18" stroke="none"/>
          <circle cx="18" cy="18" r="17.5" fill="none"/>
        </g>
        <path id="Pfad_84" data-name="Pfad 84" d="M7679.59-22636h12" transform="translate(-7667.09 22654.5)" fill="none" stroke="#000" strokeWidth="1"/>
        <path id="Pfad_102" data-name="Pfad 102" d="M7679.59-22636h12" transform="translate(22654.5 7704.09) rotate(-90)" fill="none" stroke="#000" strokeWidth="1"/>
      </svg>`,
    close: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <g id="Ellipse_97" data-name="Ellipse 97" fill="none" stroke="#c3cec4" strokeWidth="1">
          <circle cx="18" cy="18" r="18" stroke="none"/>
          <circle cx="18" cy="18" r="17.5" fill="none"/>
        </g>
        <path id="Pfad_84" data-name="Pfad 84" d="M7679.59-22636h12" transform="translate(-7667.09 22654.5)" fill="none" stroke="#000" strokeWidth="1"/>
       </svg>`,
    x: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="Component_2_33" data-name="Component 2 – 33">
      <g id="Ellipse_96" data-name="Ellipse 96" fill="none" stroke="rgba(98,135,100,0.5)" strokeWidth="1">
        <circle cx="16" cy="16" r="16" stroke="none"/>
        <circle cx="16" cy="16" r="15.5" fill="none"/>
      </g>
    </g>
    <g id="Group_237" data-name="Group 237" transform="translate(-182 -413)">
      <path id="Path_94" data-name="Path 94" d="M5624.338-1759.311l9.192,9.192" transform="translate(-5431 2184)" fill="none" stroke="#272e27" strokeWidth="1"/>
      <path id="Path_95" data-name="Path 95" d="M5624.338-1759.311l9.192,9.192" transform="translate(1952.649 6058.22) rotate(-90)" fill="none" stroke="#272e27" strokeWidth="1"/>
    </g>
  </svg>`,
    logo: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="283px" height="38.2px" viewBox="0 0 283 38.2">
      <title>mikromakro</title>
      <path d="M49.7,0c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C53.4,1.6,51.7,0,49.7,0z M117.8,10.8
        c-7.6,0-13.3,5.8-13.3,13.7c0,8,5.6,13.7,13.2,13.7c7.6,0,13.3-5.8,13.3-13.8C131.1,16.5,125.4,10.8,117.8,10.8z M117.8,33.6
        c-4.6,0-8-3.7-8-9.2c0-5.4,3.2-9.2,7.9-9.2c4.6,0,8,3.8,8,9.2C125.7,29.9,122.4,33.6,117.8,33.6z M30.9,10.8c-4,0-7.1,1.9-8.9,5.4
        c-1.5-3.5-4.4-5.4-8.5-5.4c-3.9,0-6.6,1.8-8.1,5.3v-4.6H0v26.3h5.3V25.9c0-7.1,2.9-10.6,6.9-10.6c3.4,0,5.3,2.5,5.3,6.3v16.1h5.3
        V25.9c0-7.1,2.9-10.6,6.9-10.6c3.4,0,5.3,2.5,5.3,6.3v16.1h5.3V21C40.4,14.6,36.8,10.8,30.9,10.8z M269.7,10.8
        c-7.6,0-13.3,5.8-13.3,13.7c0,8,5.6,13.7,13.2,13.7c7.6,0,13.3-5.8,13.3-13.8C283,16.5,277.3,10.8,269.7,10.8z M269.8,33.6
        c-4.6,0-8-3.7-8-9.2c0-5.4,3.2-9.2,7.9-9.2c4.6,0,8,3.8,8,9.2C277.7,29.9,274.4,33.6,269.8,33.6z M193.7,10.8
        c-6,0-10.3,3.6-11.3,8.9l4.3,1.3c1-3.6,3.1-5.5,6.7-5.5c3.6,0,5.9,2.3,6,6.1l-7.1,1.3c-6.2,1.1-9,3.7-9,7.9c0,4.6,3.4,7.6,8.4,7.6
        c3.7,0,6.5-1.7,8.1-4.8v4.3h4.9V21.4C204.7,15,200.5,10.8,193.7,10.8z M199.4,26.2c0,4.7-2.8,8.1-6.6,8.1c-2.4,0-4.1-1.3-4.1-3.6
        c0-2.5,1.8-3.7,5.5-4.3l5.2-1V26.2z M167.5,10.8c-4,0-7.1,1.9-8.9,5.4c-1.5-3.5-4.4-5.4-8.5-5.4c-3.9,0-6.6,1.8-8.1,5.3v-4.6h-5.3
        v26.3h5.3V25.9c0-7.1,2.9-10.6,6.9-10.6c3.4,0,5.3,2.5,5.3,6.3v16.1h5.3V25.9c0-7.1,2.9-10.6,6.9-10.6c3.4,0,5.3,2.5,5.3,6.3v16.1
        h5.3V21C177,14.6,173.4,10.8,167.5,10.8z M47.1,37.7h5.3V11.4h-5.3V37.7z M93.1,15.7v-4.3h-5.3v26.3h5.3V26.2c0-7.6,2.2-10.5,6-10.5
        c0.9,0,1.7,0.2,2.4,0.5l1.8-4.4c-0.9-0.6-2.1-1-3.6-1C96.9,10.9,94.5,12.2,93.1,15.7z M245,15.7v-4.3h-5.3v26.3h5.3V26.2
        c0-7.6,2.2-10.5,6-10.5c0.9,0,1.7,0.2,2.4,0.5l1.8-4.4c-0.9-0.6-2.1-1-3.6-1C248.8,10.9,246.5,12.2,245,15.7z M72.6,22.9
        c4-3.4,8.1-7.8,10.3-11.5h-6.2c-2.8,4.4-8.1,9.3-11.7,11.7V11.4h-5.3v26.3h5.3v-8.8c0.5-0.4,2.4-1.7,3.9-2.9
        C72.2,29,75,33.4,77,37.7h6.2C80.5,31.5,75.6,25.5,72.6,22.9z M224.4,22.9c4-3.4,8.1-7.8,10.3-11.5h-6.2c-2.8,4.4-8.1,9.3-11.7,11.7
        V11.4h-5.3v26.3h5.3v-8.8c0.5-0.4,2.4-1.7,3.9-2.9c3.4,3.1,6.1,7.4,8.2,11.7h6.2C232.4,31.5,227.5,25.5,224.4,22.9z"/>
      </svg>`,
  },
};

export default theme;
