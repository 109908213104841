// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-en-about-tsx": () => import("./../../../src/pages/en/about.tsx" /* webpackChunkName: "component---src-pages-en-about-tsx" */),
  "component---src-pages-en-careers-tsx": () => import("./../../../src/pages/en/careers.tsx" /* webpackChunkName: "component---src-pages-en-careers-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-impressum-tsx": () => import("./../../../src/pages/en/impressum.tsx" /* webpackChunkName: "component---src-pages-en-impressum-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-privacy-tsx": () => import("./../../../src/pages/en/privacy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-tsx" */),
  "component---src-pages-en-services-tsx": () => import("./../../../src/pages/en/services.tsx" /* webpackChunkName: "component---src-pages-en-services-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriere-tsx": () => import("./../../../src/pages/karriere.tsx" /* webpackChunkName: "component---src-pages-karriere-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("./../../../src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */),
  "component---src-pages-praxis-tsx": () => import("./../../../src/pages/praxis.tsx" /* webpackChunkName: "component---src-pages-praxis-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

