module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-react-helmet-async@1.1.0_afa473ed0a4cea69b4235daa959ab2b5/node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@2.9.0_gatsby@2.29.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mikromakro","short_name":"mikromakro","start_url":"/","background_color":"#EFEFEA","theme_color":"#EFEFEA","display":"minimal-ui","icon":"src/images/m_m.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ed125c63d88fa5f3898b4e4e9391f9e1"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-react-i18next@0.0.27_53045ba9f8aab4532d62143c555ffacc/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vusers/tom/webroot/mikro-makro.com/htdocs/frontend/locales","languages":["en","de"],"defaultLanguage":"de","siteUrl":"https://mikro-makro.com/","i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"redirect":true,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/(contact|impressum|privacy|about|services|careers)","getLanguageFromPath":true,"languages":["en"]},{"matchPath":"/:lang?/(kontakt|impressum|leistungen|praxis|datenschutz|karriere)","getLanguageFromPath":true,"languages":["de"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
